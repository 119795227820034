$('document').ready(function(){

    if(isIE()){
        $('.product-selector').hide();
    }

    $('#searchTerm').autocomplete({
        delay: 0, /* only if data remains local */
        source: searchTermJSON, // see the CMS component for this variable
        select: function(event, ui){
            makeRecommendation(ui.item.hvalue);
        }
    });

    $('#productSelectionForm').submit(function(ev){
        $('.ui-menu').hide();
        ev.preventDefault();
        // If the search matches a search term, run that
        let matchedTerm = searchTermJSON.find(term => term.label === $('#searchTerm').val());
        if(typeof matchedTerm != 'undefined'){
            makeRecommendation(matchedTerm.hvalue);
        } else {
            // If it does not then run searchAJAX
            let target = document.querySelector('#searchResultsTarget');
            target.innerHTML = "";

            $('.loadingSpinner').show();
            $('.noResults').hide();
            $('.resultsIntro').hide();
            $('#searchResultsTarget').removeClass('hasResults');

            $.get('?event=searchAJAX&search="' + $('#searchTerm').val() + '"&productSearchOnly=1', function (response) {
                $('.loadingSpinner').hide();
                let parsedResponse = JSON.parse(response);

                if (parsedResponse.data.products.length > 0) {
                    $('.resultsIntro').show();
                    $('#searchResultsTarget').addClass('hasResults');
                    parsedResponse.data.products.forEach(function (object, index) {
                        let product = createProduct(object)
                        target.appendChild(product);
                    });
                    jurisdictionShowHide();
                } else {
                    $('.resultsIntro').hide();
                    $('#searchResultsTarget').removeClass('hasResults');
                    $('.noResults').show()
                }
            });
        }
    });

    $('#searchClearance').click(function() {
        let products = document.querySelector('#searchResultsTarget');
        let searchInput = document.querySelector('#searchTerm');
        $('.noResults').hide();
        $('.resultsIntro').hide();
        $('#searchResultsTarget').removeClass('hasResults');
        products.innerHTML = "";
        searchInput.value = "";
    });

    $('#jurisdictionSelector').change(function(){
        let jurisdictionID = $('#jurisdictionSelector').val();
        jurisdictionShowHide();
    });

});

var makeRecommendation = function(templates){
    $('.loadingSpinner').show();
    $('.noResults').hide();
    $('.resultsIntro').hide();
    $('#searchResultsTarget').removeClass('hasResults');

    $.get('?event=objectsToProducts&search=' + templates.toString(), function( response ) {

        $('.loadingSpinner').hide();
        $('#searchResultsTarget').addClass('hasResults');

        let parsedResponse = JSON.parse(response);
        let target = document.querySelector('#searchResultsTarget');
        target.innerHTML = "";

        if(parsedResponse.data.length > 0){
            $('.resultsIntro').show();
            parsedResponse.data.forEach(function(object, index) {
                let product = createProduct(object)
                target.appendChild(product);
            });
            jurisdictionShowHide();
        }
        else {
            $('.resultsIntro').hide();
            $('#searchResultsTarget').removeClass('hasResults');
            $('.noResults').show()
        }
    })
}

var jurisdictionShowHide = function(){
    let jurisdictionID = $('#jurisdictionSelector').val();
    if(jurisdictionID > 0){
        $('.e_product').hide();
        $('.e_jurId_' + jurisdictionID).show();
    } else {
        $('.e_product').show();
    }
    if($('#searchResultsTarget').children(':visible').length == 0) {
        $('.resultsIntro').hide();
        $('#searchResultsTarget').removeClass('hasResults');
        $('.noResults').show();
    } else {
        $('.noResults').hide();
        $('.resultsIntro').show();
        $('#searchResultsTarget').addClass('hasResults');
    }
}

var createProduct = function(object){
    let template = document.querySelector('#searchResult');

    let clone = template.content.cloneNode(true);
    let productTitleHref = clone.querySelector('.e_name_container');
    let productTitleText = clone.querySelector('.e_name_actual');
    let productButton = clone.querySelector('.getStartedButton');
    let productJurFlagsLabel = clone.querySelector('.e_jurItem');
    let productDescription = clone.querySelector('.e_shortDesc');
    let productJurisdictionSpanContainer = clone.querySelector('.wrap_jurFlag');
    let jurisdictionIDs = object.JURISDICTIONIDLIST;
    let jurisdictionIDList = [jurisdictionIDs];
    if(typeof jurisdictionIDs == "string") {
        jurisdictionIDList = jurisdictionIDs.split(',');
    }

    if(typeof object.URL != 'undefined'){
        productTitleHref.href = object.URL;
    } else {
        productTitleHref.href = ele.uri.buildUrl("product",{productid:object.PRODUCTID});
    }
    productTitleText.textContent = object.PRODUCTNAME;
    productDescription.innerHTML = object.PRODUCTDESCRIPTION + "&hellip;";
    productButton.href = productTitleHref.href;

    if(jurisdictionIDList.length > 0){
        jurisdictionIDList.forEach(function(value, index) {
            productJurFlagsLabel.classList.add("e_jurId_" + value);
        });
    }

    return clone;
}

var isIE = function() {
    return (window.navigator.userAgent.match(/MSIE|Trident/) !== null);
}


